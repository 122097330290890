import { Alert, Button } from '@fountain/fountain-ui-components';
import { Grid, Typography } from '@material-ui/core';
import { WorkflowDataCollectionStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { REACT_APP_MONOLITH_BASE_URL } from 'runtimeEnvVars';

import {
  makeIsCandidateAiEnabled,
  makeSelectIsAccountFountainAiEnabled,
} from 'containers/Auth_old/selectors';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../../StageSettingCard';
import { SettingSwitch } from '../../../SettingSwitch';
import { BotPlatformToggleGroup } from '../../BotPlatformToggleGroup';
import {
  CaiPlatformToggleGroup,
  CaiPlatformTypes,
} from '../../CaiPlatformToggleGroup';
import {
  AUTO_ADVANCE_ON_COMPLETION,
  IS_HIDDEN_FROM_PORTAL,
  SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE,
  SHOULD_NOTIFY_WHEN_DOCUMENTS_UPLOADED,
  SHOW_ONE_QUESTION_AT_A_TIME,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

import type { BotPlatformTypes } from '../../BotPlatformToggleGroup';

type DataKeyTypes =
  | typeof IS_HIDDEN_FROM_PORTAL
  | typeof AUTO_ADVANCE_ON_COMPLETION
  | typeof SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE
  | typeof SHOULD_NOTIFY_WHEN_DOCUMENTS_UPLOADED
  | typeof SHOW_ONE_QUESTION_AT_A_TIME;

type SubstageDataKeyTypes =
  | typeof SHOULD_NOTIFY_WHEN_DOCUMENTS_UPLOADED
  | typeof SHOW_ONE_QUESTION_AT_A_TIME;

export interface DataCollectionFormCardProps {
  stage: WorkflowDataCollectionStage;
  dataCollectionBotSaveError?: string | null;
}

export const DataCollectionFormCard: VFC<DataCollectionFormCardProps> = ({
  stage,
  dataCollectionBotSaveError,
}) => {
  const styles = useStyles();
  const intl = useIntl();
  const { accountSlug } = useParams<{ accountSlug: string }>();

  const { setStage } = useContext(StageContext);

  const isFountainAiEnabled = useSelector(
    makeSelectIsAccountFountainAiEnabled(),
  );
  const isCAiEnabled = useSelector(makeIsCandidateAiEnabled());

  const onToggleChange =
    (key: DataKeyTypes | BotPlatformTypes | CaiPlatformTypes) => () => {
      setStage(
        produce(stage, draftStage => {
          if (
            key === IS_HIDDEN_FROM_PORTAL ||
            key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE
          ) {
            draftStage.advanced_settings[key] =
              !draftStage.advanced_settings[key];
          } else {
            draftStage.additional_info[key] = !draftStage.additional_info[key];
          }
        }),
      );
    };

  const labelsByDataKey: Record<DataKeyTypes, string> = {
    [IS_HIDDEN_FROM_PORTAL]: intl.formatMessage(
      messages.showStageInApplicantPortal,
    ),
    [AUTO_ADVANCE_ON_COMPLETION]: intl.formatMessage(
      messages.automaticallyMoveApplicantToNextStage,
    ),
    [SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE]: intl.formatMessage(
      messages.shouldAutoAdvanceWhenLabelsComplete,
    ),
    [SHOULD_NOTIFY_WHEN_DOCUMENTS_UPLOADED]: intl.formatMessage(
      messages.notifyOwnerWhenDataSubmitted,
    ),
    [SHOW_ONE_QUESTION_AT_A_TIME]: intl.formatMessage(
      messages.showOneQuestionAtATime,
    ),
  };

  const substageLabelsByDataKey: Record<SubstageDataKeyTypes, string> = {
    [SHOULD_NOTIFY_WHEN_DOCUMENTS_UPLOADED]: intl.formatMessage(
      messages.notifyOwnerWhenDataSubmitted,
    ),
    [SHOW_ONE_QUESTION_AT_A_TIME]: intl.formatMessage(
      messages.showOneQuestionAtATime,
    ),
  };

  const logicJumpTooltip = intl.formatMessage(messages.removeLogicToEdit);

  const editQuestionsHref = `${REACT_APP_MONOLITH_BASE_URL}/${accountSlug}/data_collection_stages/${stage.external_id}/edit`;
  const isMissingQuestions =
    !stage.additional_info.data_fields_count ||
    stage.additional_info.data_fields_count <= 0;
  const isSubStage = Boolean(stage.parent_stage_id);
  const hasStageLabels = Boolean(stage.has_stage_labels);
  const labelsToRender = isSubStage ? substageLabelsByDataKey : labelsByDataKey;

  return (
    <StageSettingCard
      variant="default"
      title={intl.formatMessage(messages.dataCollectionForm)}
    >
      <Grid>
        {isMissingQuestions && (
          <Alert type="danger" fullWidth className={styles.questionAlert}>
            <Grid className={styles.alertBodyContainer}>
              <Grid>{intl.formatMessage(messages.missingQuestions)}</Grid>
              <Grid>
                <a href={editQuestionsHref} target="_blank">
                  {intl.formatMessage(messages.setQuestions)}
                </a>
              </Grid>
            </Grid>
          </Alert>
        )}
        {dataCollectionBotSaveError && (
          <Alert type="danger" fullWidth className={styles.questionAlert}>
            <Grid className={styles.alertBodyContainer}>
              <Grid>{dataCollectionBotSaveError}</Grid>
            </Grid>
          </Alert>
        )}
        {isFountainAiEnabled && (
          <BotPlatformToggleGroup
            title={intl.formatMessage(messages.enableDataCollectionBot)}
            onToggle={onToggleChange}
            platforms={{
              widget_bot_enabled: !!stage.additional_info.widget_bot_enabled,
              sms_bot_enabled: !!stage.additional_info.sms_bot_enabled,
              t2a_bot_enabled: !!stage.additional_info.t2a_bot_enabled,
            }}
          />
        )}
        {isCAiEnabled && (
          <CaiPlatformToggleGroup
            title={intl.formatMessage(messages.enableCaiDataCollectionBot)}
            onToggle={onToggleChange}
            platforms={{
              cai_widget_enabled: !!stage.additional_info.cai_widget_enabled,
              cai_sms_enabled: !!stage.additional_info.cai_sms_enabled,
              cai_t2bot_enabled: !!stage.additional_info.cai_t2bot_enabled,
            }}
          />
        )}
        <Grid className={styles.cardBody}>
          {Object.keys(labelsToRender).map(dataKey => {
            const key = dataKey as DataKeyTypes;

            let checked = false;
            let disabled = false;
            let tooltip;

            if (
              key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE &&
              !hasStageLabels
            ) {
              return null;
            }

            if (key === IS_HIDDEN_FROM_PORTAL) {
              checked = !stage.advanced_settings[key];
            } else if (key === SHOULD_AUTO_ADVANCE_WHEN_LABELS_COMPLETE) {
              checked = !!stage.advanced_settings[key];
            } else {
              checked = !!stage.additional_info[key];
            }

            if (
              key === SHOW_ONE_QUESTION_AT_A_TIME &&
              stage.additional_info.logic_jumps_exist
            ) {
              disabled = true;
              tooltip = logicJumpTooltip;
            }

            return (
              <SettingSwitch
                key={key}
                dataKey={key}
                checked={checked}
                onChange={onToggleChange(key)}
                label={labelsByDataKey[key]}
                tooltipDescription={tooltip}
                disabled={disabled}
              />
            );
          })}
        </Grid>
        <Grid>
          <Button
            type="secondary"
            href={editQuestionsHref}
            target="_blank"
            className={styles.editQuestionsButton}
            aria-label={intl.formatMessage(messages.editQuestions)}
          >
            <Typography variant="h4" color="primary">
              {intl.formatMessage(messages.editQuestions)}
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </StageSettingCard>
  );
};
