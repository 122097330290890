import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  moveApplicantAfterBookingSession: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.moveApplicantAfterBookingSession`,
    defaultMessage: 'Move applicant to next stage after booking a session',
  },
  moveApplicantAfterBookingSessionSubStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.moveApplicantAfterBookingSessionSubStage`,
    defaultMessage: 'Move applicant to next substage after booking a session',
  },
  openingActiveByBookedSlots: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.openingActiveByBookedSlots`,
    defaultMessage: 'Activate or deactivate opening based on slot availability',
  },
  sendSessionReminders: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.sendSessionReminders`,
    defaultMessage:
      'Send session reminders even if applicants have moved to a different stage (recommended)',
  },
  showStageInApplicantPortal: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.showStageInApplicantPortal`,
    defaultMessage: 'Show stage in applicant portal progress tracker',
  },
  showOneSessionPerTimeframe: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.showOneSessionPerTimeframe`,
    defaultMessage: 'Show one session per timeframe',
  },
  shouldAutoAdvanceWhenLabelsComplete: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.shouldAutoAdvanceWhenLabelsComplete`,
    defaultMessage:
      'Automatically move applicant to next stage when all labels are completed',
  },
  enableSchedulerBot: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.enableSchedulerBot`,
    defaultMessage: 'Enable Scheduler Bot',
  },
  enableCaiSchedulerBot: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.enableCaiSchedulerBot`,
    defaultMessage: 'Enable CAI Scheduler Bot',
  },
  postInterviewMoveApplicantToNextStage: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.postInterviewMoveApplicantToNextStage`,
    defaultMessage:
      'Move applicant to next stage if applicant is marked attended via post interview notification',
  },
  sessions: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.sessions`,
    defaultMessage: 'sessions',
  },
  days: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.days`,
    defaultMessage: 'days',
  },
  recipient: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.recipient`,
    defaultMessage: 'Recipient',
  },
  openingActiveByBookedSlotsAdditionalInfo: {
    id: `app.containers.WorkflowEditor.components.StageDetail.SchedulerStage.SchedulerCard.BaseSettings.openingActiveByBookedSlotsAdditionalInfo`,
    defaultMessage:
      'When this setting is enabled, an email will be sent to the specified user (or the funnel owner if the user does not exist) if the number of available sessions for the upcoming N days reaches the threshold. If no additional sessions are added, the opening will be deactivated when calendar availabilty is full',
  },
});
