import { Grid, Typography } from '@material-ui/core';
import React, { VFC } from 'react';
import { useIntl } from 'react-intl';

import { SettingSwitch } from '../../SettingSwitch';
import {
  CAI_SMS_ENABLED,
  CAI_T2BOT_ENABLED,
  CAI_WIDGET_ENABLED,
} from './constants';
import { messages } from './messages';
import { useStyles } from './styles';

export type CaiPlatformTypes =
  | typeof CAI_SMS_ENABLED
  | typeof CAI_WIDGET_ENABLED
  | typeof CAI_T2BOT_ENABLED;

export interface CaiPlatformToggleGroup {
  title: string;
  onToggle: (key: CaiPlatformTypes) => () => void;
  platforms: { [platformKey: string]: boolean };
}

export const CaiPlatformToggleGroup: VFC<CaiPlatformToggleGroup> = ({
  title,
  onToggle,
  platforms,
}) => {
  const styles = useStyles();
  const intl = useIntl();

  return (
    <div>
      <Typography variant="body2" className={styles.header}>
        {title}
      </Typography>

      <Grid container className={styles.itemContainer} direction="row">
        {Object.keys(platforms).map(key => {
          const platformKey = key as CaiPlatformTypes;

          return (
            <Grid item key={platformKey} className={styles.item}>
              <SettingSwitch
                key={platformKey}
                dataKey={platformKey}
                checked={platforms[platformKey]}
                onChange={onToggle(platformKey)}
                label={intl.formatMessage(messages[platformKey])}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};
