import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cai_sms_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.caiSmsEnabled`,
    defaultMessage: 'SMS/WhatsApp',
  },
  cai_widget_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.caiWidgetEnabled`,
    defaultMessage: 'Web Widget',
  },
  cai_t2bot_enabled: {
    id: `app.containers.WorkflowEditor.components.StageDetail.BotPlatformToggleGroup.caiT2BotEnabled`,
    defaultMessage: 'Text to Bot',
  },
});
